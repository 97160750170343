import React, { useContext, useReducer } from "react";
import { IAgent } from "./Agent";

const parsedQuery = new URLSearchParams(window.location.search);

const CONFIG = {
  HUB: "wss://k0s.up.railway.app",
};

function autoPrefix(url: string) {
  if (!URL.canParse(url)) {
    const httpsEnabled = window.location.protocol == "https:";
    const prefix = httpsEnabled ? "wss://" : "ws://";
    return prefix + url;
  }

  let parsedUrl = URL.parse(url)!;
  switch (parsedUrl.protocol) {
    case "http:":
    case "ws:":
      parsedUrl.protocol = "ws:";
      break;
    case "https:":
    case "wss:":
      parsedUrl.protocol = "wss:";
      break;
    default:
      throw new Error("Unsupported protocol: " + parsedUrl.protocol);
  }

  return parsedUrl.toString();
}
const connectedHub = autoPrefix(parsedQuery.get("hub") || CONFIG.HUB);
console.log(`Connected socket: ${connectedHub}`);

///
// Initial state for `useReducer`

interface Props {
  children: React.ReactNode;
}

export interface WCAddr {
  wc: string;
}

export interface DotAddr {
  dot: string;
}

export interface EnsAddr {
  ens: string;
}

export interface SolAddr {
  sol: string;
}

export type Addr = WCAddr | DotAddr | EnsAddr | SolAddr;

interface AppState {
  wc: string | null;
  dot: string | null;
  ens: string | null;
  sol: string | null;
  hub: string;
  ws: WebSocket | null;
  wsError: any;
  wsState: any;
  agents: IAgent[];
  hubs: { [key: string]: number };
  addHub: (hub: string) => void;
  removeHub: (hub: string) => void;
  login: (addr: Addr) => void;
  logout: () => void;
  theme: string;
  toggleTheme: () => void;
}

// load from localStorage
const loadState = (key: string, def: any = null) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return def;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return def;
  }
};
const saveState = (key: string, state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};
const clearState = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    // Ignore write errors.
  }
};

const INIT_STATE: AppState = {
  hub: connectedHub,
  wc: loadState("wc"),
  dot: loadState("dot"),
  ens: loadState("ens"),
  sol: loadState("sol"),
  ws: null,
  wsError: null,
  wsState: null,
  agents: [],
  hubs: loadState("hubs", { "wss://k0s.up.railway.app": 0 }),
  addHub: (hub: string) => {},
  removeHub: (hub: string) => {},
  login: (addr: Addr) => {},
  logout: () => {},
  theme: loadState("theme", "light"),
  toggleTheme: () => {},
};

const decoder = new TextDecoder();

///
// Reducer function for `useReducer`

const reducer = (state: AppState, action: any) => {
  function addHub(hubs: { [key: string]: number }, hub: string) {
    let nextId = Math.max(...Object.values(hubs)) + 1;
    hubs[hub] = nextId;
    saveState("hubs", hubs);
    return hubs;
  }
  function removeHub(hubs: { [key: string]: number }, hub: string) {
    delete hubs[hub];
    saveState("hubs", hubs);
    return hubs;
  }
  switch (action.type) {
    case "INIT":
      return { ...state, wsState: "INIT" };

    case "OPEN":
      return { ...state, ws: action.payload, wsState: "OPEN" };

    case "MESSAGE": {
      let str: string | ArrayBuffer = action.payload.data;
      if (str instanceof ArrayBuffer) {
        str = decoder.decode(str);
      }
      let parsedAgents: IAgent[] = JSON.parse(str);
      if (JSON.stringify(state.agents) == JSON.stringify(parsedAgents)) {
        return state;
      }
      return { ...state, agents: parsedAgents };
    }

    case "CLOSE":
      return { ...state, wsState: "CLOSE" };

    case "ERROR":
      return { ...state, wsState: "ERROR", wsError: action.payload };

    case "NEW_HUB":
      return { ...state, hubs: addHub(state.hubs, action.payload) };

    case "REMOVE_HUB":
      return { ...state, hubs: removeHub(state.hubs, action.payload) };

    case "LOGIN_WC":
      return { ...state, wc: action.payload };

    case "LOGIN_DOT":
      return { ...state, dot: action.payload };

    case "LOGIN_ENS":
      return { ...state, ens: action.payload };

    case "LOGIN_SOL":
      return { ...state, sol: action.payload };

    case "LOGOUT": {
      clearState("wc");
      clearState("dot");
      clearState("ens");
      clearState("sol");
      return { ...state, wc: null, dot: null, ens: null, sol: null };
    }

    case "TOGGLE_THEME":
      let nextTheme = state.theme === "light" ? "dark" : "light";
      saveState("theme", nextTheme);
      return { ...state, theme: nextTheme };

    default:
      throw new Error(`Unknown type: ${action.type}`);
  }
};

///
// Connecting to the Hub watch api

const initWsConnection = (state: AppState, dispatch: any) => {
  const { wsState, hub } = state;
  // We only want this function to be performed once
  if (wsState) return;

  dispatch({ type: "INIT" });

  const watchEndpoint = new URL("/api/agents/watch", hub).toString();
  const _ws = new WebSocket(watchEndpoint);
  _ws.binaryType = "arraybuffer";

  _ws.addEventListener("open", function (event) {
    dispatch({ type: "OPEN", payload: _ws });
  });

  _ws.addEventListener("message", function (event) {
    dispatch({ type: "MESSAGE", payload: event });
  });

  _ws.addEventListener("close", function (event) {
    dispatch({ type: "CLOSE", payload: event });
  });

  _ws.addEventListener("error", function (event) {
    dispatch({ type: "ERROR", payload: event });
  });

  // Set listeners for disconnection and reconnection event.
  /*
ws.readyState = {
  ws.CONNECTING = 0
  ws.OPEN = 1
  ws.CLOSING = 2
  ws.CLOSED = 3
}
  */
};

const HubContext = React.createContext(INIT_STATE);

const HubContextProvider: React.FC<Props> = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  function addHub(hub: string) {
    dispatch({ type: "NEW_HUB", payload: hub });
  }

  function removeHub(hub: string) {
    dispatch({ type: "REMOVE_HUB", payload: hub });
  }

  function login(addr: Addr) {
    if ((addr as any).wc) {
      const wc = (addr as WCAddr).wc;
      saveState("wc", wc);
      dispatch({ type: "LOGIN_WC", payload: wc });
    }
    if ((addr as any).dot) {
      const dot = (addr as DotAddr).dot;
      saveState("dot", dot);
      dispatch({ type: "LOGIN_DOT", payload: dot });
    }
    if ((addr as any).ens) {
      const ens = (addr as EnsAddr).ens;
      saveState("ens", ens);
      dispatch({ type: "LOGIN_ENS", payload: ens });
    }
    if ((addr as any).sol) {
      const sol = (addr as SolAddr).sol;
      saveState("sol", sol);
      dispatch({ type: "LOGIN_SOL", payload: sol });
    }
  }

  function logout() {
    dispatch({ type: "LOGOUT" });
  }

  function toggleTheme() {
    dispatch({ type: "TOGGLE_THEME" });
  }

  initWsConnection(state, dispatch);

  return (
    <HubContext.Provider
      value={{ ...state, addHub, removeHub, login, logout, toggleTheme }}
    >
      {props.children}
    </HubContext.Provider>
  );
};

const useHub = () => useContext(HubContext);

export { HubContextProvider, useHub };
